import {
  Button,
  Form,
  Input,
  Popconfirm,
  Select,
  Table,
  Tag,
  message,
} from "antd";
import { RxPencil1, RxTrash } from "react-icons/rx";
import {
  useAddUserMutation,
  useDeleteUserMutation,
  useGetUsersQuery,
} from "../app/features/apiSlice";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const Users = () => {
  const { data: usersData, isLoading } = useGetUsersQuery();
  const [addUser, { isLoading: isAddingUser }] = useAddUserMutation();
  const [deleteUser] = useDeleteUserMutation();
  const [form] = Form.useForm();

  // console.log(usersData);

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => <span className="text-primary font-bold">{text}</span>,
    },
    {
      title: "Name",
      dataIndex: "displayName",
      key: "displayName",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (value) => (
        <div>
          <Tag>{value}</Tag>
        </div>
      ),
    },
    {
      title: "No. of Articles",
      dataIndex: "articles",
      key: "articles",
      render: (value) => <span>{value.length}</span>,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, item) => (
        <div className="flex items-center gap-x-2">
          <Popconfirm
            title="Delete User?"
            description="Are you sure to delete this user?"
            onConfirm={() => {
              message.loading({
                content: "Deleting...",
                key: "deleteUser",
              });
              deleteUser(item._id)
                .then((res) => {
                  if (res?.data?.success) {
                    message.success({
                      content: "Deleted",
                      key: "deleteUser",
                    });
                  } else {
                    message.error({
                      content: res?.data?.message || "Failed to delete",
                      key: "deleteUser",
                    });
                  }
                })
                .catch((err) => {
                  console.log(err);
                  message.error({
                    content: "Failed to delete",
                    key: "deleteUser",
                  });
                });
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button
              className="flex justify-center items-center text-lg"
              danger
              type="default"
            >
              <RxTrash />
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const formItems = [
    {
      label: "Email",
      name: "email",
      rules: [
        {
          required: true,
          message: "Please input user email",
        },
        {
          type: "email",
          message: "Please input a valid email",
        },
      ],
    },
    {
      label: "Name",
      name: "name",
      rules: [
        {
          required: true,
          message: "Please input user name",
        },
        {
          min: 3,
          message: "Name must be at least 3 characters",
        },
        // shouldn't include any special characters but can include space
        {
          pattern: /^[a-zA-Z ]+$/,
          message: "Name must be alphanumeric",
        },
      ],
    },
    {
      label: "Password",
      name: "password",
      rules: [
        {
          required: true,
          message: "Please input user password",
        },
        {
          min: 6,
          message: "Password must be at least 6 characters",
        },
      ],
    },
  ];

  const onFinish = (values) => {
    const { name, email, password, role } = values;

    addUser({ name, email, password, role })
      .then((res) => {
        form.resetFields();
        if (res?.data?.success) {
          message.success("User added successfully");
        } else {
          message.error(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong");
      });
  };

  return (
    <main className="ml-28 p-4">
      <h2 className="text-3xl mt-4 mb-6">
        All <span className="text-primary">Users</span>
      </h2>
      <div className="max-w-7xl grid xl:grid-cols-2 mt-8">
        <section>
          <Table
            rowKey="_id"
            columns={columns}
            dataSource={usersData?.users}
            loading={isLoading}
            key={(item) => item._id}
          />
        </section>
        <section>
          <Form
            {...formItemLayout}
            name="createCategory"
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            // onValuesChange={onValuesChange}
            // validateMessages={validateMessages}
          >
            {formItems.map((item) => (
              <Form.Item
                key={item.name}
                label={item.label}
                name={item.name}
                hasFeedback
                rules={item.rules}
              >
                <Input />
              </Form.Item>
            ))}

            <Form.Item
              label="Role"
              name="role"
              required
              initialValue={"Author"}
            >
              <Select style={{ width: 120 }}>
                <Select.Option value="Author">Author</Select.Option>
                <Select.Option value="Admin">Admin</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item wrapperCol={{ ...formItemLayout.wrapperCol, offset: 6 }}>
              <Button type="primary" htmlType="submit" loading={isAddingUser}>
                Add User
              </Button>
            </Form.Item>
          </Form>
        </section>
      </div>
    </main>
  );
};

export default Users;
