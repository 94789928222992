import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { appConfig } from "../../config/app.config";

export const resourcesSlice = createApi({
  reducerPath: "resources",
  tagTypes: ["All"],
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.resourcesServerUrl,
  }),

  endpoints: (builder) => ({
    getAllResources: builder.query({
      query: () => "/api/resources/all",
      providesTags: ["All"],
    }),

    deleteResource: builder.mutation({
      query: (id) => ({
        url: `/api/resources/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["All"],
    }),
  }),
});

export const { useGetAllResourcesQuery, useDeleteResourceMutation } =
  resourcesSlice;
