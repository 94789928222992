// export const appConfig = {
//   mainSiteUrl: "http://localhost:3000",
//   baseUrl: "http://localhost:5000",
//   apiBaseUrl: "http://localhost:8000",
//   resourcesServerUrl: "https://venus.sdprojectbucket.xyz",
// };

export const appConfig = {
  mainSiteUrl: "https://themountainmovers.ca",
  baseUrl: "https://admin.themountainmovers.ca",
  apiBaseUrl: "https://api.themountainmovers.ca",
  resourcesServerUrl: "https://pool.themountainmovers.ca",
};
