import { Button, Divider, Form, Input, Popconfirm, message } from "antd";
import { useDispatch } from "react-redux";
import { useChangePasswordMutation } from "../app/features/apiSlice";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const Settings = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [changePassword, { isLoading }] = useChangePasswordMutation();

  const onFinish = (values) => {
    changePassword({ password: values.password })
      .then(() => {
        message.success("Password changed successfully!");
        form.resetFields();
        dispatch({ type: "root/logout" });
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong!");
      });
  };

  return (
    <main className="ml-28 p-4">
      <Divider orientation="left" plain>
        <span className="font-bold">
          <span className="text-primary">Change Password</span>
        </span>
      </Divider>
      <section className="mt-6 max-w-7xl grid grid-cols-4">
        <div className="col-span-3">
          <Form
            {...formItemLayout}
            name="changePassword"
            onFinish={onFinish}
            form={form}
            // onFinishFailed={onFinishFailed}
            // onValuesChange={onValuesChange}
            // validateMessages={validateMessages}
          >
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Please input password!",
                },
              ]}
            >
              <Input.Password className="" />
            </Form.Item>

            <Form.Item
              name="repeatPassword"
              label="Repeat Password"
              rules={[
                {
                  required: true,
                  message: "Please input repeat password!",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject("Passwords don't match!");
                  },
                }),
              ]}
            >
              <Input.Password className="" />
            </Form.Item>

            <Form.Item wrapperCol={{ ...formItemLayout.wrapperCol, offset: 6 }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoading}
                className=""
              >
                Change Password
              </Button>
            </Form.Item>
          </Form>
        </div>

        <div className="">
          {/* Logout button */}

          <Popconfirm
            title="Logout?"
            description="Are you sure to logout?"
            onConfirm={() => {
              dispatch({ type: "root/logout" });
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" danger className="w-full">
              Logout
            </Button>
          </Popconfirm>
        </div>
      </section>
    </main>
  );
};

export default Settings;
