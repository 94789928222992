import React from "react";
import ReactDOM from "react-dom/client";
import appTheme from "./config/theme.config";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import "./styles/index.css";
import App from "./App";
import { store } from "./app/store";

// ant design theme customization
const theme = {
  token: {
    colorPrimary: appTheme.primaryColor,
  },
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ConfigProvider theme={theme}>
      <App />
    </ConfigProvider>
  </Provider>
);
