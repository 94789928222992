import {
  useDeleteSliderMutation,
  useGetInquiryListQuery,
  useGetSlidersQuery,
  useGetSubscriberListQuery,
} from "../app/features/apiSlice";
import { Button, Divider, Table, message } from "antd";
import SliderForm from "../components/SliderForm";
import { RxTrash } from "react-icons/rx";
import MiscellaneousForm from "../components/MiscellaneousForm";

const Dashboard = () => {
  const { data: subscriberList, isLoading: subscriberListLoading } =
    useGetSubscriberListQuery();

  const { data: inquiryList, isLoading: inquiryListLoading } =
    useGetInquiryListQuery();

  const { data: reviewSlidersList, isLoading: reviewSlidersListLoading } =
    useGetSlidersQuery();

  const [deleteSlider] = useDeleteSliderMutation();

  const subscriberColumns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => <span className="">{text}</span>,
    },
    {
      title: "Subscribed At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value) => (
        <span>
          {new Date(value).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </span>
      ),
    },
  ];

  const inquiryColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <span className="">{text}</span>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => <span className="">{text}</span>,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      render: (text) => <span className="">{text}</span>,
    },
    {
      title: "Move From",
      dataIndex: "moveFrom",
      key: "moveFrom",
      render: (text) => <span className="">{text}</span>,
    },
    {
      title: "Move To",
      dataIndex: "moveTo",
      key: "moveTo",
      render: (text) => <span className="">{text}</span>,
    },
    {
      title: "Service Type",
      dataIndex: "typeOfMove",
      key: "typeOfMove",
      render: (text) => <span className="">{text}</span>,
    },
    {
      title: "Move Date",
      dataIndex: "moveDate",
      key: "moveDate",
      render: (value) =>
        value ? (
          <span>
            {new Date(value).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "2-digit",
            })}
          </span>
        ) : (
          "N/A"
        ),
    },
  ];

  const reviewSlidersColumns = [
    {
      title: "Image",
      dataIndex: "relatedImage",
      key: "relatedImage",
      render: (text) => (
        <img src={text} alt="" className="w-12 h-12 rounded-md" />
      ),
    },
    {
      title: "Review Quote",
      dataIndex: "qoute",
      key: "qoute",
      render: (text) => <span className="">{text}</span>,
    },
    {
      title: "Quote By",
      dataIndex: "qouteBy",
      key: "qouteBy",
      render: (text) => <span className="">{text}</span>,
    },
    {
      title: "Ordered Service",
      dataIndex: "qouteByPosition",
      key: "qouteByPosition",
      render: (text) => <span className="">{text}</span>,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, item) => (
        <div className="flex items-center gap-x-2">
          {/* <Button
            className="flex justify-center items-center text-lg"
            type="default"
          >
            <RxPencil1 />
          </Button> */}
          <Button
            className="flex justify-center items-center text-lg"
            danger
            type="default"
            onClick={async () => {
              message.loading({ content: "Deleting...", key: "deleteSlider" });

              try {
                await deleteSlider(item._id);
                message.success({
                  content: "Slider deleted successfully!",
                  key: "deleteSlider",
                });
              } catch (error) {
                message.error({
                  content: "Something went wrong!",
                  key: "deleteSlider",
                });
              }
            }}
          >
            <RxTrash />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <main className="ml-28 p-4">
      {/* <h2 className="text-3xl mt-4 mb-6">
        <span className="text-primary">Dashboard</span>
      </h2> */}

      <Divider orientation="left" plain>
        <span className="font-bold">
          <span className="text-primary">Subscribers</span>
        </span>
      </Divider>

      <section className="max-w-3xl mt-6">
        <Table
          columns={subscriberColumns}
          dataSource={subscriberList?.subscribers}
          loading={subscriberListLoading}
          rowKey="_id"
          key={(item) => item._id}
        />
      </section>

      <Divider orientation="left" plain>
        <span className="font-bold">
          <span className="text-primary">Inquiries</span>
        </span>
      </Divider>

      <section className="max-w-7xl mt-6">
        <Table
          columns={inquiryColumns}
          dataSource={inquiryList?.inquiries}
          loading={inquiryListLoading}
          rowKey="_id"
          key={(item) => item._id}
        />
      </section>

      <Divider orientation="left" plain>
        <span className="font-bold">
          <span className="text-primary">Review Sliders</span>
        </span>
      </Divider>

      <section className="mt-6 grid xl:grid-cols-2 gap-4">
        <div>
          <Table
            columns={reviewSlidersColumns}
            dataSource={reviewSlidersList?.sliders}
            loading={reviewSlidersListLoading}
            rowKey="_id"
            key={(item) => item._id}
          />
        </div>
        <div>
          <SliderForm />
        </div>
      </section>

      <Divider orientation="left" plain>
        <span className="font-bold">
          <span className="text-primary">Miscellaneous</span>
        </span>
      </Divider>

      <section className="mt-6 max-w-2xl">
        <MiscellaneousForm />
      </section>
    </main>
  );
};

export default Dashboard;
