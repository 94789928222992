import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { appConfig } from "../../config/app.config";

export const apiSlice = createApi({
  reducerPath: "api",
  tagTypes: [
    "Users",
    "Articles",
    "Categories",
    "Article",
    "Slider",
    "Miscellaneous",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.apiBaseUrl,
    // prepare headers sending token from local storage
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("V__TOKEN");
      //   console.log(token);
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },

    // handle errors
    async onFetchError(error, { getState }) {
      if (error.status === 403 || error.status === 401) {
        // redirect to login page
        window.location.href = "/login";
      }
    },
  }),

  endpoints: (builder) => ({
    // auth status
    authStatus: builder.query({
      query: () => "/api/auth",
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        // get data from endpoint
        const result = await queryFulfilled;

        // console.log(result);

        // if data.status is unauthorized, redirect to login page
        if (result.data.status === "unauthorized") {
          dispatch({ type: "root/logout" });
        }

        // if data.status is authorized, set user data
        if (result.data.status === "authorized") {
          dispatch({ type: "root/setUser", payload: result.data.user });
        }
      },
    }),

    getUsers: builder.query({
      query: () => "/api/auth/users",
      providesTags: ["Users"],
    }),

    addUser: builder.mutation({
      query: (body) => ({
        url: "/api/auth/add-user",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Users"],
    }),

    deleteUser: builder.mutation({
      query: (id) => ({
        url: "/api/auth/delete-user/" + id,
        method: "DELETE",
      }),
      invalidatesTags: ["Users"],
    }),

    changePassword: builder.mutation({
      query: (body) => ({
        url: "/api/auth/change-password",
        method: "POST",
        body,
      }),
    }),

    getCategories: builder.query({
      query: () => "/api/meta/category",
      providesTags: ["Categories"],
    }),

    addCategory: builder.mutation({
      query: (body) => ({
        url: "/api/meta/category",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Categories"],
    }),

    deleteCategory: builder.mutation({
      query: (id) => ({
        url: "/api/meta/category/" + id,
        method: "DELETE",
      }),
      invalidatesTags: ["Categories"],
    }),

    addArticle: builder.mutation({
      query: (body) => ({
        url: "/api/article/create",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Articles"],
    }),

    getArticles: builder.query({
      query: (status) => "/api/article/all?status=" + status,
      providesTags: ["Articles"],
    }),

    getArticle: builder.query({
      query: (slug) => "/api/article/single/" + slug + "?ref=admin",
      providesTags: ["Article"],
    }),

    updateArticle: builder.mutation({
      query: (body) => ({
        url: "/api/article/update/" + body.slug,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Articles", "Article"],
    }),

    deleteArticle: builder.mutation({
      query: (slug) => ({
        url: "/api/article/delete/" + slug,
        method: "DELETE",
      }),
      invalidatesTags: ["Articles"],
    }),

    updateStatus: builder.mutation({
      query: (slug) => ({
        url: "/api/article/update-status/" + slug,
        method: "POST",
      }),
      invalidatesTags: ["Articles", "Article"],
    }),

    getSubscriberList: builder.query({
      query: () => "/api/index/subscriber-list",
    }),

    getInquiryList: builder.query({
      query: () => "/api/index/inquiry-list",
    }),

    addSlider: builder.mutation({
      query: (body) => ({
        url: "/api/index/sliders",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Slider"],
    }),

    getSliders: builder.query({
      query: () => "/api/index/sliders",
      providesTags: ["Slider"],
    }),

    deleteSlider: builder.mutation({
      query: (id) => ({
        url: "/api/index/sliders/" + id,
        method: "DELETE",
      }),
      invalidatesTags: ["Slider"],
    }),

    addMiscellaneous: builder.mutation({
      query: (body) => ({
        url: "/api/index/miscellaneous",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Miscellaneous"],
    }),

    getMiscellaneous: builder.query({
      query: () => "/api/index/miscellaneous",
      providesTags: ["Miscellaneous"],
    }),

    updateMiscellaneous: builder.mutation({
      query: (body) => ({
        url: "/api/index/miscellaneous/" + body.id,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Miscellaneous"],
    }),
  }),
});

export const {
  useAuthStatusQuery,
  useGetUsersQuery,
  useAddUserMutation,
  useGetCategoriesQuery,
  useAddCategoryMutation,
  useAddArticleMutation,
  useGetArticlesQuery,
  useGetArticleQuery,
  useUpdateArticleMutation,
  useDeleteArticleMutation,
  useUpdateStatusMutation,
  useGetSubscriberListQuery,
  useGetInquiryListQuery,
  useAddSliderMutation,
  useGetSlidersQuery,
  useDeleteSliderMutation,
  useAddMiscellaneousMutation,
  useGetMiscellaneousQuery,
  useUpdateMiscellaneousMutation,
  useDeleteCategoryMutation,
  useChangePasswordMutation,
  useDeleteUserMutation,
} = apiSlice;
