// import logo from "../assets/images/logo.png";
import {
  RxFrame,
  RxDashboard,
  RxHome,
  RxImage,
  RxGear,
  RxPerson,
} from "react-icons/rx";
import { useLocation, Link } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/svgs/logo.svg";

const inActive =
  "w-full h-8 p-2 flex justify-center items-center cursor-pointer  border-l-8 border-r-0 border-b-0 border-t-0 border-solid border-transparent  my-2 text-2xl";

const active =
  "w-full h-8 bg-secondary  border-l-8 border-r-0 border-b-0 border-t-0 border-solid border-primary p-2 flex justify-center items-center cursor-pointer my-2 text-2xl";

const Sidebar = () => {
  const location = useLocation();

  // console.log(location.pathname);

  return (
    <section className="w-24 bg-white h-screen fixed flex flex-col justify-start items-center pt-4">
      <Link to="/">
        {/* <img
          src={logo}
          alt="VortCMS"
          className="w-12 h-12 inline-bock mb-6 cursor-pointer"
        /> */}
        <Logo className="w-16 h-16 inline-bock mb-2 cursor-pointer" />
      </Link>
      <Link to="/" className="mb-2 !text-black">
        <div className={location.pathname === "/" ? active : inActive}>
          <RxHome className="font-bold" />
        </div>
      </Link>
      <Link to="/articles" className="mb-2 !text-black">
        <div
          className={
            location.pathname.includes("/articles") ? active : inActive
          }
        >
          <RxDashboard className="font-bold" />
        </div>
      </Link>
      <Link to="/categories" className="mb-2 !text-black">
        <div
          className={
            location.pathname?.includes("/categories") ? active : inActive
          }
        >
          <RxFrame className="font-bold" />
        </div>
      </Link>
      <Link to="/users" className="mb-2 !text-black">
        <div
          className={location.pathname?.includes("/users") ? active : inActive}
        >
          <RxPerson className="font-bold" />
        </div>
      </Link>
      <Link to="/media-library" className="mb-2 !text-black">
        <div
          className={
            location.pathname?.includes("/media-library") ? active : inActive
          }
        >
          <RxImage className="font-bold" />
        </div>
      </Link>
      <Link to="/settings" className="mb-2 !text-black">
        <div
          className={
            location.pathname?.includes("/settings") ? active : inActive
          }
        >
          <RxGear className="font-bold" />
        </div>
      </Link>

      {/* <div className="mt-auto pb-3 text-center text-sm">
        {" "}
        &copy; 2021{" "}
        <span className=" text-primary underline cursor-pointer">
          VortXpert
        </span>
      </div> */}
    </section>
  );
};

export default Sidebar;
