import {
  FileImageOutlined,
  InboxOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Button, message, Skeleton, Table, Tabs, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { useEffect, useState } from "react";
import { appConfig } from "../config/app.config";
import { RxCopy, RxTrash } from "react-icons/rx";

const MediaLibrary = () => {
  const props = {
    name: "file",
    multiple: false,
    action: `${appConfig.resourcesServerUrl}/api/resources/upload/single`,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        setRefetch((prev) => !prev);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const propsImageUpload = {
    name: "file",
    action: `${appConfig.resourcesServerUrl}/api/resources/upload/single`,
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        setRefetch((prev) => !prev);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const columns = [
    {
      title: "File Name",
      dataIndex: "fileName",
      key: "fileName",
      render: (text) => <span className="">{text}</span>,
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      render: (text) => <span className="">{text}</span>,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => <span className="">{text}</span>,
    },
    {
      title: "Uploaded At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value) => (
        <span>
          {new Date(value).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </span>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, item) => (
        <div className="flex items-center gap-x-2">
          <Button
            className="flex justify-center items-center text-lg"
            type="default"
            onClick={() => {
              navigator.clipboard.writeText(item.url);
              message.success("Copied URL to clipboard");
            }}
          >
            <RxCopy />
          </Button>
          <Button
            className="flex justify-center items-center text-lg"
            danger
            type="default"
            onClick={async () => {
              message.loading({
                content: "Deleting...",
                key: "delete",
              });
              const res = await fetch(
                `${appConfig.resourcesServerUrl}/api/resources/delete/${item.uuid}`,
                {
                  method: "DELETE",
                }
              );
              if (res.status === 200) {
                message.success({
                  content: "Deleted successfully",
                  key: "delete",
                });
                setRefetch((prev) => !prev);
              } else {
                message.error({
                  content: "Something went wrong",
                  key: "delete",
                });
              }
            }}
          >
            <RxTrash />
          </Button>
        </div>
      ),
    },
  ];

  const [mediaData, setMediaData] = useState([]);
  const [imagesData, setImagesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);

  // fetch media data
  useEffect(() => {
    setLoading(true);
    fetch(`${appConfig.resourcesServerUrl}/api/resources/all`)
      .then((res) => res.json())
      .then((data) => {
        // filter images => type: type === "image"
        const images = data?.resources.filter((item) => item.type === "image");
        // filter others where type is not image
        const others = data?.resources.filter((item) => item.type !== "image");
        setMediaData(others);
        setImagesData(images);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [refetch]);

  // console.log(mediaData);
  // console.log(imagesData);

  const tabs = [
    {
      key: "1",
      label: "Images",
      children: (
        <div>
          <div className="grid grid-cols-4 gap-3">
            {loading ? (
              <>
                {/* // loading skeleton 16 times */}
                {Array(12)
                  .fill()
                  .map((_, i) => (
                    <Skeleton.Image
                      active={true}
                      style={{
                        width: "100%",
                        height: "160px",
                      }}
                    />
                  ))}
              </>
            ) : (
              <>
                {imagesData.map((item) => (
                  <div className="relative group rounded-md">
                    <img
                      src={item.url}
                      alt={item.fileName}
                      className="w-full h-40 object-cover rounded-md"
                    />
                    <div className="absolute bottom-0 left-0 w-full h-full bg-black bg-opacity-30 z-100 items-center justify-center hidden group-hover:flex transition-all duration-500 ease-in-out rounded-md">
                      <Button
                        className="flex justify-center items-center text-lg"
                        type="default"
                        onClick={() => {
                          navigator.clipboard.writeText(item.url);
                          message.success("Copied URL to clipboard");
                        }}
                      >
                        <RxCopy />
                      </Button>
                      <Button
                        className="flex justify-center items-center text-lg ml-3"
                        danger
                        type="default"
                        onClick={async () => {
                          message.loading({
                            content: "Deleting...",
                            key: "delete",
                          });
                          const res = await fetch(
                            `${appConfig.resourcesServerUrl}/api/resources/delete/${item.uuid}`,
                            {
                              method: "DELETE",
                            }
                          );
                          if (res.status === 200) {
                            message.success({
                              content: "Deleted successfully",
                              key: "delete",
                            });
                            setRefetch((prev) => !prev);
                          } else {
                            message.error({
                              content: "Something went wrong",
                              key: "delete",
                            });
                          }
                        }}
                      >
                        <RxTrash />
                      </Button>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      ),
    },
    {
      key: "2",
      label: "Others",
      children: (
        <div>
          <Table
            columns={columns}
            dataSource={mediaData}
            loading={loading}
            rowKey="_id"
            key={(item) => item._id}
          />
        </div>
      ),
    },
  ];

  return (
    <main className="ml-28 p-4">
      <h2 className="text-3xl mt-4 mb-6">
        Media <span className="text-primary">Library</span>
      </h2>
      <section className="grid grid-cols-2 mt-8 mb-6  max-w-6xl  gap-x-4">
        <div>
          <ImgCrop rotationSlider aspectSlider quality={1}>
            <Upload.Dragger
              {...propsImageUpload}
              style={{ backgroundColor: "#fff" }}
            >
              <p className="ant-upload-drag-icon">
                <FileImageOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload an{" "}
                <span className="text-primary font-bold">Image</span>
              </p>
            </Upload.Dragger>
          </ImgCrop>
        </div>
        <div>
          <Upload.Dragger {...props} style={{ backgroundColor: "#fff" }}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload a{" "}
              <span className="text-primary font-bold">Video</span>
              {" or "}
              <span className="text-primary font-bold">Document</span>
            </p>
          </Upload.Dragger>
        </div>
      </section>

      <section className="mt-14 max-w-6xl">
        <Tabs
          defaultActiveKey="1"
          size={"large"}
          style={{ marginBottom: 32 }}
          items={tabs}
        />
      </section>
    </main>
  );
};

export default MediaLibrary;
