import { Button, Form, Input, Select, message } from "antd";
import { useAddSliderMutation } from "../app/features/apiSlice";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

function SliderForm() {
  const [form] = Form.useForm();
  const [addSlider, { isLoading: isAdding }] = useAddSliderMutation();

  const onFinish = (values) => {
    // console.log("Success:", values);
    const { qoute, qouteBy, qouteByPosition, relatedImage } = values;

    addSlider({
      qoute,
      qouteBy,
      qouteByPosition,
      relatedImage,
    })
      .then(() => {
        form.resetFields();
        message.success("Slider added successfully!");
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  return (
    <div>
      <Form
        {...formItemLayout}
        name="sliders"
        onFinish={onFinish}
        form={form}
        // onFinishFailed={onFinishFailed}
        // onValuesChange={onValuesChange}
        // validateMessages={validateMessages}
      >
        <Form.Item
          name="qoute"
          label="Review Quote"
          initialValue={""}
          rules={[
            {
              required: true,
              message: "Please input Quote!",
            },
          ]}
        >
          <Input.TextArea className="!min-h-[100px]" />
        </Form.Item>

        <Form.Item
          name="qouteBy"
          label="Quote By"
          initialValue={""}
          rules={[
            {
              required: true,
              message: "Please input Quote by!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="qouteByPosition"
          label="Ordered Service"
          initialValue={""}
          rules={[
            {
              required: true,
              message: "This field is required!",
            },
          ]}
        >
          {/* <option value={"Residential Moving"}>Residential Moving</option>
          <option value={"Commercial Moving"}>Commercial Moving</option>
          <option value={"Junk Removal"}>Junk Removal</option>
          <option value={"Packing/Unpacking"}>Packing/Unpacking</option> */}
          <Select defaultValue={""} placeholder="Select service type">
            <Select.Option value={"Residential Moving"}>
              Residential Moving
            </Select.Option>
            <Select.Option value={"Commercial Moving"}>
              Commercial Moving
            </Select.Option>
            <Select.Option value={"Junk Removal"}>Junk Removal</Select.Option>
            <Select.Option value={"Packing/Unpacking"}>
              Packing/Unpacking
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="relatedImage"
          label="Image URL"
          initialValue={""}
          rules={[
            {
              required: true,
              message: "This field is required!",
            },
            {
              // check it is a valid url
              pattern: new RegExp(
                "^((https?|ftp|smtp):\\/\\/)?(www\\.)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$"
              ),
              message: "Please input a valid url!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ ...formItemLayout.wrapperCol, offset: 6 }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={isAdding}
            className=""
          >
            Add Review Slider
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default SliderForm;
