import { Navigate, Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";

const ProtectedRoutes = ({ authenticated }) => {
  if (!authenticated) return <Navigate to="/login" />;
  return (
    <>
      <Sidebar />
      <Outlet />
    </>
  );
};

export default ProtectedRoutes;
