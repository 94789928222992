const appTheme = {
  primaryColor: "#32AB80",
  secondaryColor: "#a7f3d0",
  bgColor: "#ecfdf5",
  light: {
    primaryColor: "",
    secondaryColor: "",
  },
  dark: {
    primaryColor: "",
    secondaryColor: "",
  },
};

export default appTheme;
