import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoutes from "./components/ProtectedRoutes";
import Login from "./pages/Login";
import Users from "./pages/Users";
import Dashboard from "./pages/Dashboard";
import PageNotFound from "./pages/PageNotFound";
import CreateArticle from "./pages/CreateArticle";
import MediaLibrary from "./pages/MediaLibrary";
import Categories from "./pages/Categories";
import Settings from "./pages/Settings";
import { useAuthStatusQuery } from "./app/features/apiSlice";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import Articles from "./pages/Articles";
import EditArticle from "./pages/EditArticle";

const App = () => {
  const { isError, isLoading } = useAuthStatusQuery();
  const root = useSelector((state) => state.root);
  const token = localStorage.getItem("V__TOKEN");

  if (isLoading)
    return (
      <div className="h-screen w-full flex justify-center items-center">
        <Spin size="large" />
      </div>
    );

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          element={<ProtectedRoutes authenticated={token ? true : false} />}
        >
          <Route path="/" element={<Dashboard />} />
          <Route path="/articles" element={<Articles />} />
          <Route path="/articles/add" element={<CreateArticle />} />
          <Route path="/articles/edit/:slug" element={<EditArticle />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/media-library" element={<MediaLibrary />} />
          <Route path="/users" element={<Users />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
