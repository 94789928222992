import { Button, Form, Input, message } from "antd";
import {
  useAddMiscellaneousMutation,
  useGetMiscellaneousQuery,
  //   useUpdateMiscellaneousMutation,
} from "../app/features/apiSlice";
import { useEffect } from "react";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

function MiscellaneousForm() {
  const [form] = Form.useForm();
  const { data, isLoading, isFetching } = useGetMiscellaneousQuery();
  const [addMiscellaneous, { isLoading: addLoading }] =
    useAddMiscellaneousMutation();
  //   const [updateMiscellaneous] = useUpdateMiscellaneousMutation();

  const onFinish = (values) => {
    // console.log("Received values of form: ", values);
    const { numberOfCustomers, numberOfCompanies, bannerArticle } = values;

    addMiscellaneous({
      numberOfCustomers: Number(numberOfCustomers),
      numberOfCompanies: Number(numberOfCompanies),
      bannerArticle,
    })
      .then((res) => {
        //   console.log(res);
        form.resetFields();
        message.success(res.data?.message);
      })
      .catch((err) => {
        console.log(err);
        message.error(err.data?.message);
      });
  };

  // set initial values
  useEffect(() => {
    if (data?.miscellaneous) {
      form.setFieldsValue({
        numberOfCustomers: data?.miscellaneous?.numberOfCustomers,
        numberOfCompanies: data?.miscellaneous?.numberOfCompanies,
        bannerArticle: data?.miscellaneous?.bannerArticle?.slug,
      });
    }
  }, [data]);

  return (
    <div>
      <Form
        {...formItemLayout}
        name="miscellaneousForm"
        onFinish={onFinish}
        form={form}
        // initialValues={data?.miscellaneous}
        // onFinishFailed={onFinishFailed}
        // onValuesChange={onValuesChange}
        // validateMessages={validateMessages}
      >
        <Form.Item
          name="numberOfCustomers"
          label="Number of Customers"
          initialValue={""}
          rules={[
            {
              required: true,
              message: "This feild is required!",
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          name="numberOfCompanies"
          label="Number of Companies"
          initialValue={""}
          rules={[
            {
              required: true,
              message: "This feild is required!",
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          name="bannerArticle"
          label="Banner Article Slug"
          initialValue={""}
          rules={[
            {
              required: true,
              message: "This feild is required!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ ...formItemLayout.wrapperCol, offset: 6 }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={addLoading}
            className=""
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default MiscellaneousForm;
