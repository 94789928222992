import { useParams } from "react-router-dom";
import { Button, Form, Input, Select, Spin, Upload, message } from "antd";
import { RxChevronLeft } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
import RichEditor from "../components/RichEditor";
import { useRef, useState } from "react";
import {
  useGetArticleQuery,
  useGetCategoriesQuery,
  useGetUsersQuery,
} from "../app/features/apiSlice";
import { useUpdateArticleMutation } from "../app/features/apiSlice";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const EditArticle = () => {
  const { slug } = useParams();
  const { data: articleData, isLoading } = useGetArticleQuery(slug);
  const { data: usersData } = useGetUsersQuery();
  const { data: categoriesData } = useGetCategoriesQuery();
  const [updateArticle] = useUpdateArticleMutation();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const submitBtnRef = useRef(null);
  const [saveType, setSaveType] = useState("draft");
  const [richContent, setRichContent] = useState(
    articleData?.article?.content || ``
  );

  //   console.log(articleData);

  if (isLoading)
    return (
      <div className="h-screen w-full flex justify-center items-center">
        <Spin size="default" />
      </div>
    );

  // set initial values
  form.setFieldsValue({
    title: articleData?.article?.title,
    slug: articleData?.article?.slug,
    author: articleData?.article?.author?.displayName,
    category: articleData?.article?.category?.displayName,
    tags: articleData?.article?.tags?.join(", "),
    description: articleData?.article?.description,
  });

  const onFinish = (values) => {
    // console.log("Success:", values);

    message.loading({
      content: "Updating article...",
      key: "updateArticle",
    });
    let data = {};

    data.slug = values.slug;
    data.title = values.title;
    data.featuredImage = articleData?.article?.featuredImage;
    data.author =
      // find user id from user name
      usersData?.users?.find((user) => user.displayName === values.author)._id;

    if (values.description) data.description = values.description;

    if (values.category)
      data.category =
        // find category id from category name
        categoriesData?.categories?.find(
          (category) => category.displayName === values.category
        )._id;

    if (values.tags) {
      data.tags = values.tags.split(",").map((tag) => tag.trim());
    }

    data.content = richContent;

    const words = data.content.trim().split(/\s+/).length;
    const time = Math.ceil(words / 225);

    data.readTime = time;
    data.published = articleData?.article?.published;

    // console.log(data);
    updateArticle(data)
      .then((res) => {
        // form.resetFields();
        // setRichContent(``);
        if (res?.data?.success) {
          message.success({
            content: "Article updated successfully",
            key: "updateArticle",
          });
          // navigate("/articles");
        } else {
          message.error("Unable to update article. Please try again");
        }
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong");
      });
  };

  return (
    <main className="ml-28 p-4">
      <h2 className="text-3xl mt-4 mb-6">
        Edit <span className="text-primary">Article</span>
      </h2>

      <section className="mb-10 flex justify-between items-center">
        <Link to="/articles">
          <Button className="flex justify-center items-center text-lg">
            <RxChevronLeft />
          </Button>
        </Link>
        <div>
          <Button
            className="mr-2 font-bold"
            type={articleData?.article?.published ? "primary" : "default"}
            onClick={() => {
              setSaveType("draft");
              submitBtnRef.current.click();
            }}
          >
            Save
          </Button>
          {/* {articleData?.article?.published ? (
            <></>
          ) : (
            <Button
              type="primary"
              className="font-bold"
              onClick={() => {
                setSaveType("publish");
                submitBtnRef.current.click();
              }}
            >
              Publish
            </Button>
          )} */}
        </div>
      </section>

      <section className="grid 2xl:grid-cols-3 gap-x-4 gap-y-8">
        <div className="pt-0 pr-0">
          <Form
            {...formItemLayout}
            name="createArticle"
            onFinish={onFinish}
            form={form}
            // onFinishFailed={onFinishFailed}
            // onValuesChange={onValuesChange}
            // validateMessages={validateMessages}
          >
            <Form.Item
              name="title"
              label="Title"
              rules={[
                {
                  required: true,
                  message: "Please input the title of article!",
                },
              ]}
            >
              <Input
                disabled
                onChange={(e) => {
                  form.setFieldsValue({
                    slug: e.target.value
                      .replace(/\s+/g, "-")
                      .toLowerCase()
                      // exclude spaces and special characters and replace with hyphens
                      .replace(/[^a-zA-Z0-9-]/g, ""),
                  });
                }}
              />
            </Form.Item>

            <Form.Item
              name="slug"
              label="Slug"
              rules={[
                {
                  required: true,
                  message: "Please input the slug of the article!",
                },
                {
                  pattern: /^[a-zA-Z0-9-]*$/,
                  message: "Slug cannot contain special characters or spaces",
                },
              ]}
            >
              <Input disabled />
            </Form.Item>

            <Form.Item
              name="author"
              label="Author"
              rules={[
                {
                  required: true,
                  message: "Please select the author!",
                },
              ]}
            >
              <Select style={{ width: "100%" }}>
                {usersData?.users?.map((user) => (
                  <Select.Option key={user._id} value={user.displayName}>
                    {user.displayName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="category" label="Category">
              <Select style={{ width: "100%" }}>
                {categoriesData?.categories?.map((category) => (
                  <Select.Option
                    key={category._id}
                    value={category.displayName}
                  >
                    {category.displayName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="tags" label="Tags">
              <Input placeholder="Comma separated (e.g. tag1, tag two, tag3)" />
            </Form.Item>

            <Form.Item name="description" label="Description" initialValue={""}>
              <Input.TextArea className="!min-h-[100px]" />
            </Form.Item>

            {/* <Form.Item
              name="featuredImage"
              label="Featured Image"
              valuePropName="file"
              required
            >
              <Upload
                name="file"
                multiple={false}
                action={`${appConfig.resourcesServerUrl}/api/resources/upload/single`}
                // accept jpeg, png
                accept=".jpg, .jpeg, .png"
              >
                <Button icon={<CloudUploadOutlined />}>Click to select</Button>
              </Upload>
            </Form.Item> */}

            <Form.Item wrapperCol={{ ...formItemLayout.wrapperCol, offset: 6 }}>
              <Button
                type=""
                htmlType="submit"
                loading={false}
                className="hidden"
                ref={submitBtnRef}
              >
                Add User
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className="2xl:col-span-2">
          <RichEditor
            setRichContent={setRichContent}
            richContent={articleData?.article?.content}
          />
        </div>
      </section>
    </main>
  );
};

export default EditArticle;
