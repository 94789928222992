// import logo from "../assets/images/logo.png";
import { Button, Input, message } from "antd";
import { ReactComponent as Logo } from "../assets/svgs/logo.svg";
import { useState } from "react";
import axios from "axios";
import { appConfig } from "../config/app.config";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Login = () => {
  // global state
  const root = useSelector((state) => state.root);

  // local states
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  // if root.user is not null, redirect to the dashboard
  if (root.user) {
    message.info("You are already logged in");
    setTimeout(() => {
      navigate("/");
    }, 1000);
  }

  // handle login
  const handleLogin = async () => {
    // check if email and password are empty
    if (!email || !password) {
      return message.error("Please enter your email and password");
    }

    // check if the email is valid
    if (!/\S+@\S+\.\S+/.test(email)) {
      return message.error("Please enter a valid email");
    }

    setLoading(true);

    // send request to the server
    try {
      const response = await axios.post(
        `${appConfig.apiBaseUrl}/api/auth/login`,
        {
          email,
          password,
        }
      );

      // console.log(response.data);

      if (!response.data.success) {
        setLoading(false);
        return message.error(response.data.message);
      }

      localStorage.setItem("V__TOKEN", response.data.token);

      // redirect to the dashboard
      window.location.href = "/";
    } catch (error) {
      setLoading(false);
      console.log(error);
      message.error("Something went wrong");
    }
  };

  return (
    <main className="w-screen h-screen flex justify-center items-center relative">
      <section className="bg-white w-[400px] h-[300px] p-6 flex justify-center items-center rounded-md">
        <div className="z-10">
          <div className="flex justify-center items-center  mb-0 -mt-4">
            {/* <img src={logo} alt="VortCMS" className="w-14 h-14 block" /> */}
            {/* <span className="text-lg font-extrabold text-center text-primary">
              VortCMS
            </span> */}
            <Logo className="w-[110px] h-[110px] inline-bock" />
          </div>
          <Input
            placeholder="Enter your email"
            className="w-full my-2"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            // size="middle"
          />
          <Input.Password
            placeholder="Enter your password"
            className="w-full my-2"
            // size="middle"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            // size="middle"
            className="w-full mt-4 font-bold"
            type="primary"
            loading={loading}
            onClick={handleLogin}
          >
            Login
          </Button>
        </div>
      </section>

      <section className="absolute left-5 bottom-5 text-sm">
        &copy; 2023 VortCMS by VortExpert. All rights reserved.
      </section>
    </main>
  );
};

export default Login;
