import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
};

export const rootSlice = createSlice({
  name: "root",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    logout: (state) => {
      localStorage.removeItem("V__TOKEN");
      state.user = null;
    },
  },
});

export const { setUser, logout } = rootSlice.actions;

export default rootSlice.reducer;
