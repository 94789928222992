import { Button, Form, Input, Select, Upload, message } from "antd";
import { RxChevronLeft } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
import RichEditor from "../components/RichEditor";
import { useRef, useState } from "react";
import {
  useAddArticleMutation,
  useGetCategoriesQuery,
  useGetUsersQuery,
} from "../app/features/apiSlice";
import { CloudUploadOutlined } from "@ant-design/icons";
import { appConfig } from "../config/app.config";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const CreateArticle = () => {
  const { data: usersData } = useGetUsersQuery();
  const [addArticle, { isLoading: isAdding }] = useAddArticleMutation();
  const { data: categoriesData } = useGetCategoriesQuery();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const submitBtnRef = useRef(null);
  const [saveType, setSaveType] = useState("draft");
  const [richContent, setRichContent] = useState(``);

  const onFinish = (values) => {
    // console.log("Success:", values);
    message.loading({
      content: "Adding article...",
      key: "addArticle",
    });
    let data = {};

    data.slug = values.slug;
    data.title = values.title;
    data.featuredImage = values.featuredImage.file.response?.data?.url;
    data.author =
      // find user id from user name
      usersData?.users?.find((user) => user.displayName === values.author)._id;

    if (values.description) data.description = values.description;
    if (values.category)
      data.category =
        // find category id from category name
        categoriesData?.categories?.find(
          (category) => category.displayName === values.category
        )._id;

    if (values.tags) {
      data.tags = values.tags.split(",").map((tag) => tag.trim());
    }

    data.content = richContent;

    const words = data.content.trim().split(/\s+/).length;
    const time = Math.ceil(words / 225);

    data.readTime = time;
    data.published = saveType === "publish" ? true : false;

    // console.log(data);
    addArticle(data)
      .then((res) => {
        form.resetFields();
        setRichContent(``);
        if (res?.data?.success) {
          message.success({
            content: "Article added successfully",
            key: "addArticle",
          });
          navigate("/articles");
        } else {
          message.error("Unable to add article. Please try again");
        }
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong");
      });
  };

  return (
    <main className="ml-28 py-4 pr-8 pl-4">
      <h2 className="text-3xl mt-4 mb-6">
        Add <span className="text-primary">Article</span>
      </h2>
      <section className="mb-10 flex justify-between items-center">
        <Link to="/articles">
          <Button className="flex justify-center items-center text-lg">
            <RxChevronLeft />
          </Button>
        </Link>
        <div>
          <Button
            className="mr-2 font-bold"
            type="dashed"
            onClick={() => {
              setSaveType("draft");
              submitBtnRef.current.click();
            }}
          >
            Save
          </Button>
          <Button
            type="primary"
            className="font-bold"
            onClick={() => {
              setSaveType("publish");
              submitBtnRef.current.click();
            }}
          >
            Publish
          </Button>
        </div>
      </section>

      <section className="grid 2xl:grid-cols-3 gap-x-4 gap-y-8">
        <div className="pt-0 pr-0">
          <Form
            {...formItemLayout}
            name="createArticle"
            onFinish={onFinish}
            form={form}
            // onFinishFailed={onFinishFailed}
            // onValuesChange={onValuesChange}
            // validateMessages={validateMessages}
          >
            <Form.Item
              name="title"
              label="Title"
              rules={[
                {
                  required: true,
                  message: "Please input the title of article!",
                },
              ]}
            >
              <Input
                onChange={(e) => {
                  form.setFieldsValue({
                    slug: e.target.value
                      .replace(/\s+/g, "-")
                      .toLowerCase()
                      // exclude spaces and special characters and replace with hyphens
                      .replace(/[^a-zA-Z0-9-]/g, ""),
                  });
                }}
              />
            </Form.Item>

            <Form.Item
              name="slug"
              label="Slug"
              rules={[
                {
                  required: true,
                  message: "Please input the slug of the article!",
                },
                {
                  pattern: /^[a-zA-Z0-9-]*$/,
                  message: "Slug cannot contain special characters or spaces",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="author"
              label="Author"
              rules={[
                {
                  required: true,
                  message: "Please select the author!",
                },
              ]}
            >
              <Select style={{ width: "100%" }}>
                {usersData?.users?.map((user) => (
                  <Select.Option key={user._id} value={user.displayName}>
                    {user.displayName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="category" label="Category">
              <Select style={{ width: "100%" }}>
                {categoriesData?.categories?.map((category) => (
                  <Select.Option
                    key={category._id}
                    value={category.displayName}
                  >
                    {category.displayName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="tags" label="Tags">
              <Input placeholder="Comma separated (e.g. tag1, tag two, tag3)" />
            </Form.Item>

            <Form.Item name="description" label="Description" initialValue={""}>
              <Input.TextArea className="!min-h-[100px]" />
            </Form.Item>

            <Form.Item
              name="featuredImage"
              label="Featured Image"
              valuePropName="file"
              required
            >
              <Upload
                name="file"
                multiple={false}
                action={`${appConfig.resourcesServerUrl}/api/resources/upload/single`}
                // accept jpeg, png
                accept=".jpg, .jpeg, .png"
              >
                <Button icon={<CloudUploadOutlined />}>Click to select</Button>
              </Upload>
            </Form.Item>

            <Form.Item wrapperCol={{ ...formItemLayout.wrapperCol, offset: 6 }}>
              <Button
                type=""
                htmlType="submit"
                loading={isAdding}
                className="hidden"
                ref={submitBtnRef}
              >
                Add User
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className="2xl:col-span-2">
          <RichEditor setRichContent={setRichContent} />
        </div>
      </section>
    </main>
  );
};

export default CreateArticle;
