import { Button, Form, Input, Popconfirm, Table, message } from "antd";
import { RxPencil1, RxTrash } from "react-icons/rx";
import {
  useAddCategoryMutation,
  useDeleteCategoryMutation,
  useGetCategoriesQuery,
} from "../app/features/apiSlice";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const Categories = () => {
  const [form] = Form.useForm();
  const { data: categoriesData, isLoading } = useGetCategoriesQuery();
  const [addCategory, { isLoading: addCategoryLoading }] =
    useAddCategoryMutation();

  const [deleteCategory] = useDeleteCategoryMutation();

  const columns = [
    {
      title: "Identifier",
      dataIndex: "identifier",
      key: "identifier",
      render: (text) => <span className="text-primary font-bold">{text}</span>,
    },
    {
      title: "Display Name",
      dataIndex: "displayName",
      key: "displayName",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "No. of Articles",
      dataIndex: "articles",
      key: "articles",
      render: (value) => <span>{value.length}</span>,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value) => (
        <span>
          {new Date(value).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </span>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, item) => (
        <div className="flex items-center gap-x-2">
          {/* <Button
            className="flex justify-center items-center text-lg"
            type="default"
          >
            <RxPencil1 />
          </Button> */}
          <Popconfirm
            title="Delete Category?"
            description="Are you sure to delete this category?"
            onConfirm={() => {
              message.loading({
                content: "Deleting...",
                key: "deleteCategory",
              });
              deleteCategory(item._id)
                .then((res) => {
                  if (res?.data?.success) {
                    message.success({
                      content: "Deleted",
                      key: "deleteCategory",
                    });
                  } else {
                    message.error({
                      content: res?.data?.message || "Failed to delete",
                      key: "deleteCategory",
                    });
                  }
                })
                .catch((err) => {
                  console.log(err);
                  message.error({
                    content: "Failed to delete",
                    key: "deleteCategory",
                  });
                });
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button
              className="flex justify-center items-center text-lg"
              danger
              type="default"
            >
              <RxTrash />
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const formItems = [
    {
      label: "Display Name",
      name: "displayName",
      rules: [
        {
          required: true,
          message: "Please input category display name",
        },
        {
          pattern: /^[a-zA-Z0-9 ]*$/,
          message: "Display name cannot contain special characters",
        },
      ],
    },
  ];

  const onFinish = (values) => {
    // console.log("Success:", values);
    const { displayName, identifier } = values;

    addCategory({ displayName, identifier })
      .then((res) => {
        form.resetFields();
        if (res?.data?.success) {
          message.success("Category added successfully");
        } else {
          message.error(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong");
      });
  };

  return (
    <main className="ml-28 p-4">
      <h2 className="text-3xl mt-4 mb-6">
        All <span className="text-primary">Categories</span>
      </h2>
      <div className="max-w-[1380px] grid xl:grid-cols-2 mt-8">
        <section>
          <Table
            columns={columns}
            dataSource={categoriesData?.categories}
            loading={isLoading}
            rowKey="_id"
            key={(item) => item._id}
          />
        </section>
        <section>
          <Form
            {...formItemLayout}
            name="createCategory"
            form={form}
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            // onValuesChange={onValuesChange}
            // validateMessages={validateMessages}
          >
            {formItems.map((item) => (
              <Form.Item
                key={item.name}
                label={item.label}
                name={item.name}
                hasFeedback
                rules={item.rules}
              >
                <Input
                  onChange={(e) => {
                    // console.log(e.target.value);
                    form.setFieldsValue({
                      // exclude spaces and replace with hyphens
                      identifier: e.target.value
                        .replace(/\s+/g, "-")
                        .toLowerCase(),
                    });
                  }}
                />
              </Form.Item>
            ))}

            <Form.Item
              name={"identifier"}
              label="Identifier"
              rules={[
                {
                  required: true,
                  message: "Please input category identifier",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    // check if the value includes spaces
                    if (value.includes(" ")) {
                      return Promise.reject(
                        new Error("Identifier cannot contain spaces")
                      );
                    }

                    // check if the value includes special characters
                    const regex = /[!@#$%^&*(),.?":{}|<>]/;
                    if (regex.test(value)) {
                      return Promise.reject(
                        new Error(
                          "Identifier cannot contain special characters"
                        )
                      );
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item wrapperCol={{ ...formItemLayout.wrapperCol, offset: 6 }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={addCategoryLoading}
              >
                Add Category
              </Button>
            </Form.Item>
          </Form>
        </section>
      </div>
    </main>
  );
};

export default Categories;
