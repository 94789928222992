import { Button, Input, Popconfirm, Table, Tag, message } from "antd";
import { RxPencil1, RxTrash, RxPlus, RxCopy } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
import {
  useDeleteArticleMutation,
  useGetArticlesQuery,
  useUpdateStatusMutation,
} from "../app/features/apiSlice";

const Articles = () => {
  const {
    data: articlesData,
    isLoading,
    isFetching,
  } = useGetArticlesQuery("all");
  const [updateStatus] = useUpdateStatusMutation();
  const [deleteArticle] = useDeleteArticleMutation();
  const navigate = useNavigate();

  const columns = [
    {
      title: "Featured Image",
      dataIndex: "featuredImage",
      key: "featuredImage",
      render: (link) => (
        <img src={link} alt="featuredImage" className="w-12 h-12 rounded-md" />
      ),
    },
    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
      render: (text) => <span className="text-primary font-bold">{text}</span>,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Author",
      dataIndex: "author",
      key: "author",
      render: (value) => <span>{value?.displayName}</span>,
    },
    {
      title: "Status",
      dataIndex: "published",
      key: "published",
      render: (value) => (
        <div>
          {value ? (
            <Tag color="success" className="!font-bold">
              {"Published"}
            </Tag>
          ) : (
            <Tag color="cyan" className="!font-bold">
              {"Draft"}
            </Tag>
          )}
        </div>
      ),
    },
    {
      title: "Views",
      dataIndex: "views",
      key: "views",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Last Updated",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (value) => (
        <span>
          {new Date(value).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </span>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, item) => (
        <div className="flex items-center gap-x-2">
          <Button
            className="flex justify-center items-center text-lg"
            type="dashed"
            onClick={() => {
              navigator.clipboard.writeText(item.slug);
              message.success("Slug copied to clipboard");
            }}
          >
            <RxCopy />
          </Button>
          <Button
            className="flex justify-center items-center text-lg"
            type="default"
            onClick={() => {
              navigate(`/articles/edit/${item.slug}`);
            }}
          >
            <RxPencil1 />
          </Button>
          <Button
            type="default"
            className=""
            onClick={() => {
              // console.log(item);
              message.loading({
                content: item?.published ? "Unpublishing..." : "Publishing...",
                key: "updateStatus",
              });
              updateStatus(item.slug)
                .then((res) => {
                  message.success({
                    content: item?.published ? "Unpublished" : "Published",
                    key: "updateStatus",
                  });
                })
                .catch((err) => {
                  console.log(err);
                  message.error({
                    content: item?.published
                      ? "Failed to unpublish"
                      : "Failed to publish",
                    key: "updateStatus",
                  });
                });
            }}
          >
            {item?.published ? "Unpublish" : "Publish"}
          </Button>
          <Popconfirm
            title="Delete Article"
            description="Are you sure to delete this article?"
            onConfirm={() => {
              message.loading({
                content: "Deleting...",
                key: "deleteArticle",
              });
              deleteArticle(item.slug)
                .then((res) => {
                  message.success({
                    content: "Deleted",
                    key: "deleteArticle",
                  });
                })
                .catch((err) => {
                  console.log(err);
                  message.error({
                    content: "Failed to delete",
                    key: "deleteArticle",
                  });
                });
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button
              className="flex justify-center items-center text-lg"
              danger
              type="default"
            >
              <RxTrash />
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <main className="ml-28 p-4">
      <h2 className="text-3xl mt-4 mb-6">
        All <span className="text-primary">Articles</span>
      </h2>
      <section className="mb-4 mt-8 flex justify-between items-center">
        <div>
          <Link to="/articles/add">
            <Button
              className="flex justify-center items-center text-lg"
              type="primary"
            >
              <RxPlus />
            </Button>
          </Link>
        </div>
        <div>
          <Input.Search
            placeholder="Search articles"
            loading={false}
            enterButton
          />
        </div>
      </section>
      <section className="mt-6">
        <Table
          columns={columns}
          dataSource={articlesData?.articles}
          rowKey="_id"
          loading={isLoading || isFetching}
          key={(item) => item._id}
        />
      </section>
    </main>
  );
};

export default Articles;
