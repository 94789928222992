import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./features/rootSlice";
import { apiSlice } from "./features/apiSlice";
import { resourcesSlice } from "./features/resourcesSlice";

export const store = configureStore({
  reducer: {
    root: rootReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    [resourcesSlice.reducerPath]: resourcesSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(apiSlice.middleware)
      .concat(resourcesSlice.middleware),
});
